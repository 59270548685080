import React, { useState } from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  padding: 40px;
  flex-direction: column;
  margin: 0 auto;
  max-width: 400px;
`;

const InputComponent = styled.input`
  border: 1px solid #bbb;
  border-radius: 4px;
  font-size: 16px;
  padding: 8px 12px;
  margin-bottom: 10px;
  width: 100%;
  box-sizing: border-box;
  :focus {
    outline: none;
    border-color: #888;
  }
`;

const Button = styled.button`
  border: none;
  background: #3498db;
  color: white;
  font-size: 16px;
  padding: 8px 12px;
  font-size: 16px;
  border-radius: 4px;
  font-weight: 500;
  width: 100%;
  margin-top: 10px;
  :hover {
    background: #2275a4;
    cursor: pointer;
  }
`;

const Login = (props) => {
  const [typed, setTyped] = useState("");

  return (
    <Container>
      <h1>FORMZINE Admin Login</h1>
      <InputComponent
        value={typed}
        onChange={(e) => setTyped(e.target.value)}
      />
      <Button onClick={() => props.setCode(typed)}>Log in</Button>
    </Container>
  );
};

export default Login;
