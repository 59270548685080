import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Login from "./Login";
import axios from "axios";
import apiRoot from "../apiRoot";

const Container = styled.div`
  display: flex;
  padding: 40px;
  flex-direction: column;
  margin: 0 auto;
  max-width: 600px;
`;

const Options = styled.div`
  display: flex;
  margin-bottom: 30px;
  @media screen and (max-width: 360px) {
    flex-direction: column;
  }
`;

const Tab = styled.div`
  border-radius: 4px;
  padding: 6px 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  font-size: 16px;
  transition: background 0.2s, color 0.2s;
  margin-right: 15px;
  border: 2px solid #42b1c0;
  background: ${(props) => (props.active ? "#42b1c0" : "transparent")};
  color: ${(props) => (!props.active ? "#3a9fac" : "white")};
`;

const Form = styled.form`
  display: flex;
  margin-top: 10px;
`;

const InputComponent = styled.input`
  border: 1px solid #bbb;
  border-radius: 4px;
  font-size: 16px;
  padding: 8px 12px;
  margin-right: 15px;
  box-sizing: border-box;
  :focus {
    outline: none;
    border-color: #888;
  }
`;

const Button = styled.button`
  border: none;
  background: #42b1c0;
  color: white;
  font-size: 16px;
  padding: 8px 12px;
  font-size: 16px;
  border-radius: 4px;
  font-weight: 500;
  :hover {
    background: #2275a4;
    cursor: pointer;
  }
`;

const ShipItem = (props) => {
  const i = props.item;
  const [text, setText] = useState("");

  return (
    <div style={{ marginBottom: "30px" }}>
      {i.shipping_name}
      <br />
      {i.shipping_street1}
      <br />
      {i.shipping_street2 && (
        <div>
          {i.shipping_street2}
          <br />
        </div>
      )}
      {i.shipping_city}, {i.shipping_state} {i.shipping_zip}{" "}
      {i.shipping_country}
      {props.shipped && i.tracking_link && i.tracking_link !== "SHIPPED" && (
        <a
          href={i.tracking_link}
          style={{ display: "block", color: "#3498db" }}
        >
          Track
        </a>
      )}
      {!props.shipped &&
        (i.tracked_shipping ? (
          <Form>
            <InputComponent
              value={text}
              onChange={(e) => setText(e.target.value)}
              placeholder="Tracking number"
            />
            <Button
              onClick={(e) => {
                e.preventDefault();
                props.ship(
                  i.shipping_country === "US"
                    ? "https://tools.usps.com/go/TrackConfirmAction.action?tLabels=" +
                        text
                    : "https://a1.asendiausa.com/tracking/?trackingnumber=" +
                        text
                );
              }}
            >
              Set tracking
            </Button>
          </Form>
        ) : (
          <Form>
            <Button
              onClick={(e) => {
                e.preventDefault();
                props.ship();
              }}
            >
              Mark as shipped
            </Button>
          </Form>
        ))}
    </div>
  );
};

const Main = () => {
  const [code, setCode] = useState(localStorage.getItem("adminkey"));
  const [tab, setTab] = useState("stats");
  const [purchases, setPurchases] = useState(null);
  const [status, setStatus] = useState(null);
  const [filter, setFilter] = useState("all");

  useEffect(() => {
    async function f() {
      const res = await axios.get(apiRoot + "/status");
      setStatus(res.data);
      const res2 = await axios.get(apiRoot + "/getPurchases?adminkey=" + code);
      setPurchases({
        success: res2.data.success,
        purchases: res2.data.purchases?.filter((i) => i.volume === 2),
      });
    }
    f();
  }, [setStatus, setPurchases, code]);

  if (!code || (purchases && !purchases.success))
    return (
      <Login
        setCode={(code) => {
          setCode(code);
          localStorage.setItem("adminkey", code);
        }}
      />
    );

  if (!purchases) return <Container>Loading</Container>;

  const p = purchases.purchases;

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  let shipped, unshipped;
  if (tab === "ship") {
    const base = p
      .filter((i) => i.physical)
      .filter((i) => {
        if (filter === "all") return true;
        if (filter === "u")
          return i.shipping_country === "US" && !i.tracked_shipping;
        if (filter === "us")
          return i.shipping_country === "US" && i.tracked_shipping;
        return i.shipping_country !== "US";
      });
    shipped = base.filter((i) => i.tracking_link);
    unshipped = base.filter((i) => !i.tracking_link);
  }

  const ship = async (i, link) => {
    let obj = {
      adminkey: code,
      order_id: i.id,
    };
    if (link) obj.trackingLink = link;
    await axios.post(apiRoot + "/addShipping", obj);
    setPurchases({
      success: true,
      purchases: p.map((x) =>
        x.id === i.id ? { ...x, tracking_link: link || "SHIPPED" } : x
      ),
    });
  };

  return (
    <Container>
      <h1>FORMZINE Admin</h1>
      <Options>
        <Tab active={tab === "stats"} onClick={() => setTab("stats")}>
          Stats
        </Tab>
        <Tab active={tab === "up"} onClick={() => setTab("up")}>
          Untracked Purchases
        </Tab>
        <Tab active={tab === "ship"} onClick={() => setTab("ship")}>
          Add Shipping
        </Tab>
      </Options>
      {tab === "stats" && (
        <div>
          <p>
            <strong>Physical Purchases </strong>
            {p.filter((i) => i.physical).length}
          </p>
          <p>
            <strong>US Untracked Purchases </strong>
            {
              p.filter(
                (i) =>
                  i.physical &&
                  i.shipping_country === "US" &&
                  !i.tracked_shipping
              ).length
            }
          </p>
          <p>
            <strong>US Tracked Purchases </strong>
            {
              p.filter(
                (i) =>
                  i.physical &&
                  i.shipping_country === "US" &&
                  i.tracked_shipping
              ).length
            }
          </p>
          <p>
            <strong>International Purchases </strong>
            {p.filter((i) => i.physical && i.shipping_country !== "US").length}
          </p>
          <p>
            <strong>Digital Purchases </strong>
            {p.filter((i) => !i.physical).length}
          </p>
          <p>
            <strong>Vol. 1 Addon Purchases </strong>
            {p.filter((i) => i.include_vol_1).length}
          </p>
          <p>
            <strong>Total Purchases </strong>
            {p.length}
          </p>
          {!status.inPreorders && (
            <p>
              <strong>Remaining For Sale </strong>
              {90 - p.filter((i) => i.physical).length}
            </p>
          )}
          <p>
            <strong>Base Total </strong>
            {formatter.format(p.reduce((q, c) => q + c.base_amt, 0) / 100)}
          </p>
          <p>
            <strong>Addon Total </strong>
            {formatter.format(5 * p.filter((i) => i.include_vol_1).length)}
          </p>
          <p>
            <strong>Shipping Total </strong>
            {formatter.format(p.reduce((q, c) => q + c.shipping, 0) / 100)}
          </p>
          <p>
            <strong>RAINN Total </strong>
            {formatter.format(
              p.reduce((q, c) => q + c.donation_rainn, 0) / 100
            )}
          </p>
          <p>
            <strong>FORM Total </strong>
            {formatter.format(p.reduce((q, c) => q + c.donation_form, 0) / 100)}
          </p>
          <p>
            <strong>Total </strong>
            {formatter.format(p.reduce((q, c) => q + c.amount, 0) / 100)}
          </p>
        </div>
      )}
      {tab === "up" && (
        <div>
          {p
            .filter(
              (i) =>
                i.physical && i.shipping_country === "US" && !i.tracked_shipping
            )
            .map((i) => (
              <div key={i.id} style={{ marginBottom: "30px" }}>
                {i.shipping_name}
                <br />
                {i.shipping_street1}
                <br />
                {i.shipping_street2 && (
                  <div>
                    {i.shipping_street2}
                    <br />
                  </div>
                )}
                {i.shipping_city}, {i.shipping_state} {i.shipping_zip}
              </div>
            ))}
        </div>
      )}
      {tab === "ship" && (
        <div>
          <Options>
            <Tab active={filter === "all"} onClick={() => setFilter("all")}>
              All
            </Tab>
            <Tab active={filter === "u"} onClick={() => setFilter("u")}>
              Untracked
            </Tab>
            <Tab active={filter === "us"} onClick={() => setFilter("us")}>
              Tracked US
            </Tab>
            <Tab active={filter === "int"} onClick={() => setFilter("int")}>
              Tracked Int
            </Tab>
          </Options>
          <h3>Unshipped</h3>
          {unshipped.map((i) => (
            <ShipItem key={i.id} item={i} ship={(link) => ship(i, link)} />
          ))}
          <h3>Shipped</h3>
          {shipped.map((i) => (
            <ShipItem key={i.id} item={i} shipped={true} />
          ))}
        </div>
      )}
    </Container>
  );
};

export default Main;
