import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Terms from "./legal/Terms";
import Privacy from "./legal/Privacy";
import { useParams } from "react-router-dom";
import axios from "axios";
import apiRoot from "./apiRoot";
import Button from "./ui/Button";

const Box = styled.div`
  margin: 0 auto 40px auto;
  padding: 40px 60px 0 60px;
  max-width: 1000px;
  a {
    color: inherit;
  }
  @media screen and (max-width: 600px) {
    padding: 40px 20px 0 20px;
  }
`;

const FooterLink = styled.div`
  font-weight: 700;
  color: rgba(0, 0, 0, 0.5);
  padding: 5px 0;
  margin-right: 20px;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
`;

const FooterText = styled.p`
  font-weight: 700;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.3);
  margin-top: 15px;
`;

const Logo = styled.img`
  max-height: 80px;
  max-width: 90vw;
  margin: 0 auto;
  display: block;
`;

const Flex = styled.div`
  display: flex;
  max-width: 800px;
  margin: 20px auto;
  @media screen and (max-width: 800px) {
    flex-direction: column;
  }
`;

const Divider = styled.div`
  margin-right: 20px;
  @media screen and (max-width: 800px) {
    margin-bottom: 20px;
  }
`;

const App = function (props) {
  const [showTerms, setShowTerms] = useState(false);
  const [showPrivacy, setShowPrivacy] = useState(false);
  const [links, setLinks] = useState(null);
  let { id } = useParams();

  useEffect(() => {
    async function f() {
      const data = await axios.post(apiRoot + "/getDownloadLink", { key: id });
      console.log(data);

      if (data.data.success === false) setLinks(false);
      else setLinks(data.data);
    }
    f();
  }, [setLinks, id]);

  return (
    <div>
      <Box>
        <div style={{ textAlign: "center" }}>
          <Logo src="/title.png" />
          {links === null && "Loading..."}
          {links === false &&
            "Your download link is invalid. Email us at formzine@artbyform.com if you ordered a digital copy of FORMZINE and aren't able to download it!"}
          {links && (
            <div
              style={{
                color: "rgba(0,0,0,0.7)",
                marginTop: "20px",
                lineHeight: "1.5",
              }}
            >
              Thanks for your FORMZINE order! You can download FORMZINE with the
              links below. You may want to use the compressed version if the
              full version is causing performance or download speed issues.
              {links.url2_uncompressed && (
                <Flex>
                  <Button color="#307aff" href={links.url2_uncompressed}>
                    Download FORMZINE Vol. 2
                  </Button>
                  <Divider />
                  <Button color="#ff5398" href={links.url2_compressed}>
                    Download FORMZINE Vol. 2 (compressed)
                  </Button>
                </Flex>
              )}
              {links.url_uncompressed && (
                <Flex>
                  <Button
                    active={links.url2_uncompressed ? false : true}
                    color="#307aff"
                    href={links.url_uncompressed}
                  >
                    Download FORMZINE Vol. 1
                  </Button>
                  <Divider />
                  <Button
                    active={links.url2_uncompressed ? false : true}
                    color="#ff5398"
                    href={links.url_compressed}
                  >
                    Download FORMZINE Vol. 1 (compressed)
                  </Button>
                </Flex>
              )}
            </div>
          )}
        </div>
        {showTerms && <Terms close={() => setShowTerms(false)} />}
        {showPrivacy && <Privacy close={() => setShowPrivacy(false)} />}
        <FooterLink onClick={() => setShowTerms(true)}>
          Terms of Service
        </FooterLink>
        <FooterLink onClick={() => setShowPrivacy(true)}>
          Privacy Policy
        </FooterLink>
        <FooterText>&copy; 2024 artbyFORM Inc.</FooterText>
      </Box>
    </div>
  );
};

export default App;
