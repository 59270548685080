import styled from "styled-components";

function hexToRgb(hex) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
}

function c(hex) {
  let rgb = hexToRgb(hex);
  return `${rgb.r}, ${rgb.g}, ${rgb.b}`;
}

const ButtonContainer = styled.div`
  width: ${(props) => (props.noflex ? "auto" : "100%")};
  cursor: ${(props) => (props.soldOut ? "default" : "pointer")};
  transition: transform 0.2s;
  position: relative;
  z-index: 5;
  transform: none;
  flex: ${(props) => (props.noflex ? "inherit" : "1")};
  display: flex;
  text-decoration: none;
  :hover {
    transform: ${(props) =>
      props.disabled || props.soldOut ? "none" : "scale(103%)"};
  }
`;

const Button = styled.div`
  border: ${(props) =>
    props.active !== undefined
      ? `2px solid ${props.soldOut ? "rgba(0,0,0,0.1)" : props.color}`
      : "none"};
  background: ${(props) =>
    props.disabled
      ? "#ddd"
      : props.active === false || props.soldOut
      ? "white"
      : props.color};
  width: 100%;
  padding: 8px 16px;
  display: flex;
  flex: 1;
  flex-direction: ${(props) => (props.active !== undefined ? "column" : "row")};
  box-sizing: border-box;
  color: ${(props) =>
    props.disabled
      ? "#333"
      : props.soldOut
      ? "rgba(0,0,0,0.45)"
      : props.active === false
      ? props.color
      : "white"};
  font-weight: bold;
  font-size: 16px;
  justify-content: center;
  align-items: center;
  transition: background 0.2s;
  cursor: ${(props) =>
    props.soldOut || props.disabled ? "default" : "pointer"};
  svg {
    width: 24px;
    height: 24px;
    margin-left: 15px;
  }
`;

const ButtonBacking = styled.div`
  width: 100%;
  height: 40px;
  background: linear-gradient(
    rgba(${(props) => c(props.color)}, 1),
    rgba(${(props) => c(props.color)}, 0)
  );
  transition: background 0.2s, height 0.2s;
  bottom: -10px;
  position: absolute;
  z-index: -1;
`;

const Component = (props) => {
  let BC = ButtonContainer;
  if (props.href) BC = ButtonContainer.withComponent("a");
  return (
    <BC
      href={props.href}
      disabled={props.disabled}
      noflex={props.noflex}
      onClick={props.onClick}
      soldOut={props.soldOut}
    >
      <Button
        disabled={props.disabled}
        color={props.color}
        soldOut={props.soldOut}
        active={props.active}
      >
        {props.children}
      </Button>
      <ButtonBacking
        active={props.active}
        color={props.disabled ? "#dddddd" : props.color}
      />
    </BC>
  );
};

export default Component;
