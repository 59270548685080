import React from "react";
import styled from "styled-components";
import Button from "../ui/Button";

const Divider = styled.div`
  margin-bottom: 15px;
`;

const Rate = styled.span`
  opacity: 0.8;
  display: inline-block;
  margin-left: 15px;
`;

const OptionText = styled.div`
  font-size: 12px;
  font-weight: 500;
`;

function ShippingMethod(props) {
  return (
    <div>
      {props.shippingMethods.map((i, index) => (
        <div key={i.id}>
          <Button
            color={i.color}
            onClick={() => props.setShippingMethod(i.id)}
            active={props.shippingMethod === i.id}
          >
            <div>
              {i.name}
              <Rate active={props.shippingMethod === i.id}>${i.rate}</Rate>
            </div>
            <OptionText>{i.info}</OptionText>
          </Button>
          {index !== props.shippingMethods.length - 1 && <Divider />}
        </div>
      ))}
    </div>
  );
}

export default ShippingMethod;
