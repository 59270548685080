import React from "react";
import styled from "styled-components";
import onClickOutside from "react-onclickoutside";

const Background = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.7);
  z-index: 10;
`;

const MainBox = styled.div`
  border-radius: 6px;
  background: white;
  padding: 30px 40px;
  width: 600px;
  max-width: 80vw;
  position: relative;

  @media screen and (max-width: 600px) {
    padding: 15px 20px;
    width: calc(100vw - 40px);
    max-height: calc(100vh - 95px);
    max-width: calc(100vw - 40px);
    position: fixed;
    border-radius: 0;
  }
`;

const Page = styled.div`
  max-height: 70vh;
  overflow-y: auto;
  padding-right: 20px;
  ${(props) => props.visible && "overflow: visible;"}
  ::-webkit-scrollbar {
    width: 12px;
    height: 18px;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-button {
    width: 0;
    height: 0;
    display: none;
  }
  ::-webkit-scrollbar-corner {
    background-color: transparent;
  }
  ::-webkit-scrollbar-thumb {
    height: 12px;
    border: 8px solid transparent;
    border-width: 0 0 0 8px;
    background-clip: padding-box;
    background-color: rgba(0, 0, 0, 0.2);
  }
`;

function Privacy(props) {
  Privacy.handleClickOutside = props.close;

  return (
    <MainBox>
      <Page>
        <h1>Privacy Policy</h1>
        <p>
          This Privacy Policy explains how we (artbyFORM Inc.) collect and use
          your information on this site. We'll keep it short and easy to
          understand! By using this site, you agree to the terms below.
        </p>
        <h3>What information we collect</h3>
        <p>
          When you place an order on this shop, we'll collect certain data in
          order to fulfill your order. This may include, for example, your name,
          email address, shipping address, and payment information. We only
          collect the data necessary to successfully fulfill your order, and all
          of this data is provided directly to us by you when you check out.
        </p>
        <h3>How we use your information</h3>
        <p>
          Metadata and aggregate information about how you use this site may be
          used internally for business analysis purposes and to improve this
          site and our products. Specific data about you will be used in order
          to place and fulfill product orders, contact you about your order, and
          potentially to respond to customer service requests and other business
          purposes relating to your order as necessary. We may also share your
          data if legally required to due to law enforcement action.
        </p>
        <h3>Who your information is shared with</h3>
        <p>
          Your data will be shared with third-party service providers who work
          with us to perform such activities as creating and fulfilling product
          orders, payment processing, and email sending. We may also share your
          data as necessary to respond to legal process, or if we or part of our
          business are acquired by an outside entity.
        </p>
        <h3>How we secure your information</h3>
        <p>
          We take efforts to ensure that unauthorized third parties will be
          unable to access your personal information. However, it is always
          possible that your information could be compromised, and we cannot
          guarantee its security. To protect your payment details, we never
          directly handle credit card information and instead work with Stripe
          to ensure payments are handled safely and securely.
        </p>
        <h3>General details</h3>
        <p>
          We reserve the right to change this policy at any time. The latest
          version can always be viewed at this page. If you have questions or
          concerns, contact us at formzine@artbyform.com.
        </p>
      </Page>
    </MainBox>
  );
}

const clickOutsideConfig = {
  handleClickOutside: () => Privacy.handleClickOutside,
};

const COPrivacy = onClickOutside(Privacy, clickOutsideConfig);

function Overlay(props) {
  return (
    <Background>
      <COPrivacy close={props.close} />
    </Background>
  );
}

export default Overlay;
