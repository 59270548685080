import React from "react";
import styled from "styled-components";
import onClickOutside from "react-onclickoutside";

const Background = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.7);
  z-index: 10;
`;

const MainBox = styled.div`
  border-radius: 6px;
  background: white;
  padding: 30px 40px;
  width: 600px;
  max-width: 80vw;
  position: relative;
  @media screen and (max-width: 600px) {
    padding: 15px 20px;
    width: calc(100vw - 40px);
    max-height: calc(100vh - 95px);
    max-width: calc(100vw - 40px);
    position: fixed;
    border-radius: 0;
  }
`;

const Page = styled.div`
  max-height: 70vh;
  overflow-y: auto;
  padding-right: 20px;
  ${(props) => props.visible && "overflow: visible;"}
  ::-webkit-scrollbar {
    width: 12px;
    height: 18px;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-button {
    width: 0;
    height: 0;
    display: none;
  }
  ::-webkit-scrollbar-corner {
    background-color: transparent;
  }
  ::-webkit-scrollbar-thumb {
    height: 12px;
    border: 8px solid transparent;
    border-width: 0 0 0 8px;
    background-clip: padding-box;
    background-color: rgba(0, 0, 0, 0.2);
  }
`;

function Terms(props) {
  Terms.handleClickOutside = props.close;

  return (
    <MainBox>
      <Page>
        <h1>Terms of service</h1>
        <p>
          These Terms of Service govern your use of this site. By using this
          site, you agree to these terms, and they constitute a binding legal
          agreement between you, the user, and us, artbyFORM Inc.
        </p>
        <h3>General terms</h3>
        <p>
          To use this site, you must be at least the age of majority within your
          jurisdiction or that you are at least 13 years of age and have
          permission to use this site from your legal parent or guardian, who
          also agrees to these terms. In your use of this service and our
          products, you must not violate any laws in your jurisdiction. You also
          agree not to reproduce, copy, or sell any portion of this site or our
          products without our express written permission, to otherwise infringe
          upon our or others' intellectual property rights, to attempt to
          disrupt the operation of our site or other websites in any way, or to
          automate use of the site's features.
        </p>
        <h3>Products</h3>
        <p>
          We reserve the right to change or discontinue this site, any of our
          products, and their prices at any time, with or without notice. All
          products are displayed as accurately as possible but these digital
          reproductions and their colors and appearances are not guaranteed to
          match the physical product exactly. We also reserve the right to limit
          sales of our products to anyone or any specific region. All products
          and this site are provided with no warranty or other guarantee, and
          our and our related entities disclaim all liability to the maximum
          extent permitted by law.
        </p>
        <h3>Donations</h3>
        <p>
          All purchases or donations made via this site are transactions with
          artbyFORM Inc., a 501(c)(3) public charity. We may note charities on
          this site as recipients of either net profits or donations. All
          amounts indicated as donations or calculated as net profits, pursuant
          to our internal policies, shall be granted to an external
          organization. We may deduct transaction fees before making these
          donations, and we may allocate transaction fees among elements of a
          purchase or grant recipients at our discretion, including but not
          limiting to covering some or all of the transaction fees from our own
          funds. The organization that funds are ultimately transferred to may
          vary from the organization mentioned on the site as the recipient of
          those revenues, for example in the event that we are unable to deliver
          funds to the mentioned organization. All funds are ultimately under
          the control and discretion of artbyFORM Inc., and we exercise ultimate
          grantmaking authority. We will strive to donate funds to the mentioned
          organization or an organization with similar mission and purpose to
          the greatest extent possible.
        </p>
        <h3>Refunds</h3>
        <p>
          Refunds are available on a case by case basis. You have 30 days from
          the date of delivery to request a refund. We will only provide refunds
          if the order arrives damaged, the incorrect order was sent, or the
          order does not arrive. Please contact us at formzine@artbyform.com to
          request further information.
        </p>
        <h3>This Agreement</h3>
        <p>
          If any part of these Terms of Service is determined to be unlawful,
          void or unenforceable, such provision shall nonetheless be enforceable
          to the fullest extent permitted by applicable law, and the
          unenforceable portion shall be deemed to be severed from these Terms
          of Service. Such determination shall not affect the validity and
          enforceability of any other remaining provisions. These Terms are
          effective until terminated by either you or us. You can terminate the
          terms by discontinuing use of these services. The failure of us to
          exercise or enforce any right or provision of these Terms of Service
          shall not constitute a waiver of such right or provision. These Terms
          of Service and any policies or operating rules posted by us on this
          site or in respect to The Service constitutes the entire agreement and
          understanding between you and us and govern your use of the Service,
          superseding any prior or contemporaneous agreements, communications
          and proposals, whether oral or written, between you and us (including,
          but not limited to, any prior versions of the Terms of Service). Any
          ambiguities in the interpretation of these Terms of Service shall not
          be construed against the drafting party. These Terms of Service and
          any separate agreements whereby we provide you Services shall be
          governed by and construed in accordance with the laws of United States
          and the Commonwealth of Virginia.
        </p>
        <h3>Contact Information</h3>
        <p>You can contact us by email at formzine@artbyform.com.</p>
      </Page>
    </MainBox>
  );
}

const clickOutsideConfig = {
  handleClickOutside: () => Terms.handleClickOutside,
};

const COPrivacy = onClickOutside(Terms, clickOutsideConfig);

function Overlay(props) {
  return (
    <Background>
      <COPrivacy close={props.close} />
    </Background>
  );
}

export default Overlay;
