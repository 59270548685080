import React from "react";
import styled from "styled-components";
import Input from "../ui/Input";
import Typeahead from "../ui/typeahead/Typeahead";
import countries from "./countries";

const Label = styled.label`
  font-size: 12px;
  font-weight: 500;
  color: #888;
  margin-bottom: 3px;
`;

const Flex = styled.form`
  display: flex;
`;

const Box = styled.div`
  flex: 1;
`;

const Divider = styled.div`
  margin-right: 15px;
`;

function ShippingAddress(props) {
  const update = (name) => (value) =>
    props.setAddress({ ...props.address, [name]: value.target.value });

  return (
    <div>
      <Label>Name</Label>
      <Input value={props.address.name} onChange={update("name")} />
      <Label>Address Line 1</Label>
      <Input value={props.address.street1} onChange={update("street1")} />
      <Label>Address Line 2</Label>
      <Input value={props.address.street2} onChange={update("street2")} />
      <Label>City</Label>
      <Input value={props.address.city} onChange={update("city")} />
      <Flex autoComplete="off">
        <Typeahead
          value={props.address.country}
          onChange={update("country")}
          items={countries}
          label="Country"
        />
        <Divider />
        {["United States", "Canada", "Australia"].includes(
          props.address.country
        ) && (
          <Typeahead
            value={props.address.state}
            onChange={update("state")}
            items={
              countries.filter((i) => i.name === props.address.country)[0]
                .states
            }
            label={props.address.country === "Canada" ? "Province" : "State"}
          />
        )}
        {["United States", "Canada", "Australia"].includes(
          props.address.country
        ) && <Divider />}
        <Box>
          <Label>ZIP/Postal Code</Label>
          <Input value={props.address.zip} onChange={update("zip")} />
        </Box>
      </Flex>
    </div>
  );
}

export default ShippingAddress;
