import React, { useState, useRef } from "react";
import styled from "styled-components";

const Container = styled.div``;

const Text = styled.p`
  font-size: 14px;
  color: rgba(0, 0, 0, 0.5);
`;

const DonationBlock = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  @media screen and (max-width: 360px) {
    flex-direction: column;
  }
`;

const LeftSide = styled.div`
  h4 {
    margin-top: 0;
    margin-bottom: 10px;
  }
  p {
    margin: 0;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.6);
    a {
      color: inherit;
    }
  }
`;

const Amount = styled.div`
  border: 2px solid rgba(0, 0, 0, ${(props) => (props.focus ? 0.4 : 0.1)});
  font-size: 22px;
  color: rgba(0, 0, 0, 0.3);
  display: flex;
  align-self: flex-start;
  align-items: center;
  padding: 4px 8px;
  margin-left: 20px;
  transition: border-color 0.2s;
  cursor: default;
  @media screen and (max-width: 360px) {
    margin-left: 0;
    margin-top: 10px;
  }
`;

const AmountInput = styled.input`
  color: rgba(0, 0, 0, 0.7);
  font-size: 22px;
  border: none;
  outline: none;
  display: inline-block;
  ::placeholder {
    color: rgba(0, 0, 0, 0.3);
  }
`;

const Divider = styled.div`
  align-self: center;

  width: 90%;
  height: 2px;
  background: rgba(0, 0, 0, 0.1);
  margin: 20px auto;
`;

function toFixed(num, precision, down = false) {
  num = Math.round(num * 1000) / 1000;
  if (down && Math.floor(num * 100) === num * 100 - 0.5)
    return (+(Math.floor(+(num + "e" + precision)) + "e" + -precision)).toFixed(
      precision
    );
  return (+(Math.round(+(num + "e" + precision)) + "e" + -precision)).toFixed(
    precision
  );
}

let filterAmount = (amount) => {
  let chars = amount.split("");
  chars = chars.filter((x) => "0123456789.".split("").includes(x));
  let val = "";
  let hasPoint = false;
  let numbersAfterPoint = 0;
  for (let char of chars) {
    if (char === ".") {
      if (!hasPoint) {
        hasPoint = true;
        val += char;
      }
    } else {
      if (hasPoint) numbersAfterPoint++;
      if (numbersAfterPoint <= 2) val += char;
    }
  }
  return val;
};

function AddDonations(props) {
  let [i1focus, seti1focus] = useState(false);
  let [i2focus, seti2focus] = useState(false);

  const i1 = useRef(null);
  const i2 = useRef(null);

  return (
    <Container>
      <Text>
        Adding extra donations is optional! Just want to pay the base price plus
        shipping? Click "Continue" below.
      </Text>
      <Text>
        Donations added here are tax-deductible to the greatest extent allowed
        under US law. artbyFORM Inc., the recipient of your donations, is a
        501(c)(3) public charity.
      </Text>
      <DonationBlock>
        <LeftSide>
          <h4>Donate to PCRF</h4>
          <p>
            Contribute extra to support{" "}
            <a target="_blank" rel="noreferrer" href="https://pcrf.net/">
              PCRF
            </a>
            , the charity we're supporting for FORMZINE.
          </p>
        </LeftSide>
        <Amount onClick={() => i1.current.focus()} focus={i1focus}>
          $
          <AmountInput
            ref={i1}
            onFocus={() => seti1focus(true)}
            onBlur={() => {
              seti1focus(false);
              props.setDonations({
                ...props.donations,
                pcrf: toFixed(Number(props.donations.pcrf), 2, true),
              });
            }}
            size={5}
            value={props.donations.pcrf}
            onChange={(e) =>
              props.setDonations({
                ...props.donations,
                pcrf: filterAmount(e.target.value),
              })
            }
            placeholder="0.00"
          />
        </Amount>
      </DonationBlock>
      <Divider />
      <DonationBlock>
        <LeftSide>
          <h4>Donate to FORM</h4>
          <p>
            Contribute extra to support us at FORM. Donations go directly toward
            funding our operations and future events.
          </p>
        </LeftSide>
        <Amount onClick={() => i2.current.focus()} focus={i2focus}>
          $
          <AmountInput
            ref={i2}
            onFocus={() => seti2focus(true)}
            onBlur={() => {
              seti2focus(false);
              props.setDonations({
                ...props.donations,
                form: toFixed(Number(props.donations.form), 2, true),
              });
            }}
            size={5}
            value={props.donations.form}
            onChange={(e) =>
              props.setDonations({
                ...props.donations,
                form: filterAmount(e.target.value),
              })
            }
            placeholder="0.00"
          />
        </Amount>
      </DonationBlock>
    </Container>
  );
}

export default AddDonations;
