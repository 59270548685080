import React from "react";
import styled from "styled-components";
import Button from "../ui/Button";

const Container = styled.div``;

const Text = styled.p`
  font-size: 14px;
  color: rgba(0, 0, 0, 0.5);
  a {
    color: inherit;
  }
`;

const Price = styled.span`
  opacity: 0.8;
  display: inline-block;
  margin-left: 15px;
`;

const Checkbox = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
  border: 2px solid ${(props) => props.color};
  background: white;
  width: 16px;
  height: 16px;
  border-radius: 16px;
  min-width: 16px;
  svg {
    width: 12px !important;
    height: 12px !important;
    color: ${(props) => props.color};
    margin-left: 0 !important;
  }
`;

function AddFormzine1(props) {
  return (
    <Container>
      <Text>
        The first volume of FORMZINE, compiled two years before this one
        (February-March 2022), is available to bundle with your FORMZINE Vol. 2
        purchase in digital form only, containing all 92 pages of submitted
        work. All proceeds from sales of FORMZINE Vol. 1 go to{" "}
        <a href="https://rainn.org">RAINN</a>.
      </Text>
      <Button
        color={props.color}
        active={props.includeVol1}
        onClick={() => props.setIncludeVol1(!props.includeVol1)}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <Checkbox color={props.color}>
            {props.includeVol1 && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={4}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m4.5 12.75 6 6 9-13.5"
                />
              </svg>
            )}
          </Checkbox>
          Add FORMZINE Vol. 1 (digital only) <Price>$5</Price>
        </div>
      </Button>
    </Container>
  );
}

export default AddFormzine1;
