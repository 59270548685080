import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { StripeProvider } from "react-stripe-elements";
import stripeKey from "./stripeKey";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Download from "./Download";
import Main from "./Admin/Main";

ReactDOM.render(
  <StripeProvider apiKey={stripeKey}>
    <React.StrictMode>
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<App />} />
          <Route path="/dl/:id" element={<Download />} />
          <Route path="/admin" element={<Main />} />
        </Routes>
      </BrowserRouter>
    </React.StrictMode>
  </StripeProvider>,
  document.getElementById("root")
);
