import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Terms from "./legal/Terms";
import Privacy from "./legal/Privacy";
import axios from "axios";
import apiRoot from "./apiRoot";
import Cart from "./Cart/Cart";
import Button from "./ui/Button";
import { Elements } from "react-stripe-elements";

const Box = styled.div`
  margin: 0 auto 40px auto;
  padding: 40px 60px 0 60px;
  max-width: 1000px;
  @media screen and (max-width: 600px) {
    padding: 40px 20px 0 20px;
  }
`;

const FooterLink = styled.div`
  font-weight: 700;
  color: rgba(0, 0, 0, 0.5);
  padding: 5px 0;
  margin-right: 20px;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
`;

const FooterText = styled.p`
  font-weight: 700;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.3);
  margin-top: 15px;
`;

const Loading = styled.div`
  margin-bottom: 20px;
  color: rgba(0, 0, 0, 0.7);
  font-size: 18px;
`;

const Logo = styled.img`
  max-height: 80px;
  max-width: 90vw;
  margin: 0 auto;
  display: block;
`;

const SideBySide = styled.div`
  display: flex;
  margin: 30px 0;
`;

const CoverBox = styled.div`
  margin-right: 40px;
  text-align: center;
  position: relative;
  @media screen and (max-width: 800px) {
    display: none;
  }
`;

const Cover = styled.img`
  max-width: 100%;
  border: 5px solid rgba(255, 255, 255, 0.5);
  box-sizing: border-box;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
  z-index: 5;
  position: relative;
  max-width: 450px;
`;

const PurchaseInfo = styled.div`
  flex: 1;
  position: relative;
  a {
    color: inherit;
  }
`;

const Options = styled.div`
  display: flex;
  margin-bottom: 15px;
  @media screen and (max-width: 360px) {
    flex-direction: column;
  }
`;

const Divider = styled.div`
  margin-right: 20px;
  @media screen and (max-width: 360px) {
    margin-right: 0;
    margin-bottom: 20px;
  }
`;

const OptionText = styled.div`
  font-size: 12px;
  font-weight: 500;
`;

const OptionTitle = styled.h4`
  margin: 0 0 5px 0;
  font-size: 16px;
`;

const Price = styled.span`
  opacity: 0.8;
  display: inline-block;
  margin-left: 5px;
`;

const Details = styled.p`
  color: rgba(0, 0, 0, 0.8);
  font-size: 14px;
  margin-top: 20px;
  z-index: 5;
  position: relative;
`;

const SamplePages = styled.a`
  color: rgba(0, 0, 0, 0.7) !important;
  font-size: ${(props) => (props.small ? "14px" : "18px")};
  cursor: pointer;
  text-decoration: none;
  display: flex;
  margin: 10px auto 0 auto;
  align-items: center;
  justify-content: center;
  text-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
  border: ${(props) => (props.small ? "1px" : "2px")} solid rgba(0, 0, 0, 0.15);
  padding: ${(props) => (props.small ? "6px" : "8px")};
  transition: background 0.2s;
  z-index: 5;
  position: relative;
  :hover {
    background: rgba(0, 0, 0, 0.03);
  }
  svg {
    width: ${(props) => (props.small ? "18px" : "24px")};
    height: ${(props) => (props.small ? "18px" : "24px")};
    margin-left: 15px;
  }
  @media screen and (max-width: 800px) {
    font-size: 14px;
    svg {
      width: 18px;
      height: 18px;
    }
  }
`;

function toDDHHMMSS(tstr) {
  var sec_num = parseInt(tstr, 10); // don't forget the second param
  var days = Math.floor(sec_num / 86400);
  var hours = Math.floor((sec_num - days * 86400) / 3600);
  var minutes = Math.floor((sec_num - days * 86400 - hours * 3600) / 60);
  var seconds = sec_num - days * 86400 - hours * 3600 - minutes * 60;

  if (hours < 10) {
    hours = "0" + hours;
  }
  if (minutes < 10) {
    minutes = "0" + minutes;
  }
  if (seconds < 10) {
    seconds = "0" + seconds;
  }
  return days + ":" + hours + ":" + minutes + ":" + seconds;
}

function getTime(end) {
  let tstr = "" + (new Date(end) - Date.now()) / 1000;
  return toDDHHMMSS(tstr);
}

const end = "Fri Mar 22 2024 13:00:00 GMT-0400 (Eastern Daylight Time)";

const App = function () {
  const [showTerms, setShowTerms] = useState(false);
  const [showPrivacy, setShowPrivacy] = useState(false);
  const [status, setStatus] = useState(null);
  const [option, setOption] = useState(null);
  const [cartOpen, setCartOpen] = useState(false);
  const [tstr, setTstr] = useState(getTime(end));

  useEffect(() => {
    async function f() {
      const res = await axios.get(apiRoot + "/status");
      setStatus(res.data);
    }
    f();
  }, [setStatus]);

  useEffect(() => {
    const a = setInterval(() => setTstr(getTime(end)), 1000);
    return () => clearInterval(a);
  }, []);

  return (
    <div>
      {cartOpen && (
        <Elements
          fonts={[
            {
              cssSrc: "https://fonts.googleapis.com/css?family=Figtree:500",
            },
          ]}
        >
          <Cart option={option} close={() => setCartOpen(false)} />
        </Elements>
      )}
      <Box>
        {status && status.isOpen && (
          <div>
            <Logo src="/title.png" />
            <SideBySide>
              <CoverBox>
                <Cover src="/cover.jpg" />
              </CoverBox>
              <PurchaseInfo>
                <Options>
                  <Button
                    color="#307aff"
                    active={option === "p"}
                    soldOut={status.soldOut}
                    onClick={() => {
                      if (!status.soldOut) setOption("p");
                    }}
                  >
                    <OptionTitle>
                      Physical + Digital <Price>$10</Price>
                    </OptionTitle>
                    <OptionText>
                      A physical copy of the printed version of FORMZINE, plus
                      the digital version featuring every submission.
                    </OptionText>
                    {status.soldOut && (
                      <strong
                        style={{
                          fontSize: "16px",
                          textAlign: "center",
                          display: "block",
                          marginTop: "10px",
                        }}
                      >
                        SOLD OUT
                      </strong>
                    )}
                  </Button>
                  <Divider />
                  <Button
                    color="#ff5398"
                    active={option === "d"}
                    onClick={() => setOption("d")}
                  >
                    <OptionTitle>
                      Digital Only <Price>$5</Price>
                    </OptionTitle>
                    <OptionText>
                      Just the digital version of FORMZINE, featuring every
                      submission from our community.
                    </OptionText>
                  </Button>
                </Options>
                {option && (
                  <Button
                    color={option === "p" ? "#307aff" : "#ff5398"}
                    onClick={() => setCartOpen(true)}
                  >
                    Purchase FORMZINE{" "}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="rgba(255,255,255,0.8)"
                      strokeWidth={2}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M14 5l7 7m0 0l-7 7m7-7H3"
                      />
                    </svg>
                  </Button>
                )}
                <Details>
                  FORMZINE Vol. 2 is a magazine created by the artbyFORM
                  community in February and March 2024. We challenged our
                  community to design a page or two-page spread of whatever
                  content they wanted. From the resulting submissions, we
                  compiled this magazine, featuring everything from posters to
                  interviews. All profits from sales of FORMZINE Vol. 2 go to{" "}
                  <a href="https://pcrf.net">PCRF</a>.
                  <br />
                  <br />
                  Pre-orders of physical copies of FORMZINE Vol. 2 will ship in
                  April 2024. Note that the physical version will not contain
                  all content featured in the digital version due to page count
                  limitations.
                  <br />
                  <br />
                  {new Date(end) > Date.now() && (
                    <div>
                      Pre-orders end in <strong>{tstr}</strong>. We'll print
                      some extra copies, but order before the pre-order period
                      ends to be sure you get a copy!
                    </div>
                  )}
                </Details>
                <SamplePages
                  href="/FORMZINE 2 Sample Pages.pdf"
                  download={true}
                >
                  Download Sample Pages
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="rgba(0,0,0,0.6)"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
                    />
                  </svg>
                </SamplePages>
              </PurchaseInfo>
            </SideBySide>
          </div>
        )}
        {status && status.isOpen === false && (
          <Loading>The site is not yet open</Loading>
        )}
        {showTerms && <Terms close={() => setShowTerms(false)} />}
        {showPrivacy && <Privacy close={() => setShowPrivacy(false)} />}
        <FooterLink onClick={() => setShowTerms(true)}>
          Terms of Service
        </FooterLink>
        <FooterLink onClick={() => setShowPrivacy(true)}>
          Privacy Policy
        </FooterLink>
        <FooterText>&copy; 2024 artbyFORM Inc.</FooterText>
      </Box>
    </div>
  );
};

const AppClosed = function () {
  const [showTerms, setShowTerms] = useState(false);
  const [showPrivacy, setShowPrivacy] = useState(false);

  return (
    <div>
      <Box>
        <div>
          <Logo src="/title.png" />
          <Details style={{ fontSize: "18px", margin: "30px 0" }}>
            FORMZINE Vol. 2 sales have now closed. Thank you for your support!
          </Details>
        </div>
        {showTerms && <Terms close={() => setShowTerms(false)} />}
        {showPrivacy && <Privacy close={() => setShowPrivacy(false)} />}
        <FooterLink onClick={() => setShowTerms(true)}>
          Terms of Service
        </FooterLink>
        <FooterLink onClick={() => setShowPrivacy(true)}>
          Privacy Policy
        </FooterLink>
        <FooterText>&copy; 2024 artbyFORM Inc.</FooterText>
      </Box>
    </div>
  );
};

export default AppClosed;
