const f = (physical, v1, donations, shipping) => {
  let total = physical ? 1000 : 500;
  if (v1) total += 500;
  if (donations.pcrf) total += donations.pcrf.replace(".", "") * 1;
  if (donations.form) total += donations.form.replace(".", "") * 1;
  if (shipping) total += shipping.rate.replace(".", "") * 1;
  return total / 100;
};

export default f;
