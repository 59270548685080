import React from "react";
import styled from "styled-components";

const CenterText = styled.div`
  margin: 40px auto;
  text-align: center;
  h3 {
    font-size: 20px;
    margin-bottom: 5px;
  }
  p {
    color: #555;
  }
`;

function Complete() {
  return (
    <CenterText>
      <h3>Your purchase is complete!</h3>
      <p>
        Thanks for buying FORMZINE! You should get an email shortly with your
        digital download link. If you have any issues or questions regarding
        your order, just send us an email at{" "}
        <strong>formzine@artbyform.com</strong>!
      </p>
    </CenterText>
  );
}

export default Complete;
