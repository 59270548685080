import React, { useState } from "react";
import styled from "styled-components";
import apiRoot from "../apiRoot";
import onClickOutside from "react-onclickoutside";
import ShippingAddress from "./ShippingAddress";
import ShippingMethod from "./ShippingMethod";
import Checkout from "./Checkout";
import countries from "./countries";
import { injectStripe } from "react-stripe-elements";
import Complete from "./Complete";
import AddDonations from "./AddDonations";
import getTotal from "./getTotal";
import Button from "../ui/Button";
import AddFormzine1 from "./AddFormzine1";

const Background = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.7);
  z-index: 10;
`;

const MainBox = styled.div`
  background: white;
  padding: 30px 30px 100px 30px;
  width: 600px;
  max-width: 80vw;
  position: relative;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);

  @media screen and (max-width: 1000px) {
    padding: 15px 20px 80px 20px;
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
    flex: 1;
  }
`;

const Page = styled.div`
  max-height: 75vh;
  ${(props) => props.visible && "overflow: visible;"}
  ::-webkit-scrollbar {
    width: 12px;
    height: 18px;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-button {
    width: 0;
    height: 0;
    display: none;
  }
  ::-webkit-scrollbar-corner {
    background-color: transparent;
  }
  ::-webkit-scrollbar-thumb {
    height: 12px;
    border: 8px solid transparent;
    border-width: 0 0 0 8px;
    background-clip: padding-box;
    background-color: rgba(0, 0, 0, 0.2);
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const Title = styled.h2`
  margin: 0;
  font-size: 28px;
`;

const Close = styled.div`
  cursor: pointer;
`;

const Footer = styled.div`
  box-shadow: 1px 0 4px 1px rgba(0, 0, 0, 0.1);
  padding: 15px 30px;
  position: absolute;
  bottom: 0;
  left: 0;
  width: calc(100% - 60px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media screen and (max-width: 1000px) {
    padding: 10px 20px;
    width: calc(100% - 40px);
  }
`;

const LightButton = styled.button`
  cursor: pointer;
  font-weight: 700;
  color: #444;
  border: none;
  outline: none;
  padding: 0;
  font-size: 16px;
  padding: 10px 0;
  background: transparent;
  :hover {
    color: black;
  }
`;

const ErrorMessage = styled.div`
  color: hsl(5, 95%, 30%);
  background: hsl(5, 95%, 92%);
  padding: 12px 16px;
  font-weight: 700;
  font-weight: 500;
  font-size: 14px;
  margin: 0 10px;
`;

const Container = styled.div`
  display: flex;
  border-radius: 4px;

  @media screen and (max-width: 1000px) {
    width: calc(100vw - 40px);
    height: calc(100vh - 95px);
    max-width: 600px;
    position: fixed;
    border-radius: 0;
    flex-direction: column;
  }
`;

const CurrentCart = styled.div`
  max-height: 100%;
  min-height: 100%;
  background: #f8f8f8;
  flex: 1;
  padding: 30px;
  @media screen and (max-width: 1000px) {
    display: none;
  }
`;

const CurrentCartTitle = styled.h3`
  font-size: 16px;
  margin: 0;
`;

const CartItem = styled.div`
  margin: 10px 0;
  font-size: 14px;
  padding-right: 60px;
  position: relative;
`;

const ItemName = styled.span``;

const ItemPrice = styled.span`
  position: absolute;
  top: 0;
  right: 0;
`;

function Cart(props) {
  let [page, setPage] = useState(0);
  let [error, setError] = useState();
  let [working, setWorking] = useState(false);
  let [address, setAddress] = useState({
    street1: "",
    street2: "",
    city: "",
    state: "",
    zip: "",
    country: "",
  });
  let [includeVol1, setIncludeVol1] = useState(false);
  let [donations, setDonations] = useState({ pcrf: "", form: "" });
  let [shippingMethod, setShippingMethod] = useState();
  let [shippingMethods, setShippingMethods] = useState([]);
  let [email, setEmail] = useState("");
  let [name, setName] = useState("");

  Cart.handleClickOutside = props.close;

  function pageToTitle(page) {
    if (page === 0) return "Add FORMZINE Vol. 1";
    if (page === 1) return "Add Donations";
    if (page === 2) return "Shipping Details";
    if (page === 3) return "Shipping Method";
    if (page === 4) return "Check out";
    if (page === 5) return "Order confirmation";
  }

  function pageToButton(page) {
    if (page === 0 || page === 1 || page === 2 || page === 3) return "Continue";
    if (page === 4)
      return (
        "Pay $" +
        getTotal(
          props.option === "p",
          includeVol1,
          donations,
          shippingMethods.filter((i) => i.id === shippingMethod)[0]
        ).toFixed(2)
      );
    if (page === 5) return "Close";
  }

  async function nextStep() {
    if (working) {
      return;
    }
    if (page === 0) {
      setPage(1);
    }
    if (page === 1) {
      if (props.option === "p") {
        setPage(2);
      } else {
        setWorking(true);
        setPage(4);
      }
    }
    if (page === 2) {
      if (
        !address.country ||
        !address.street1 ||
        !address.city ||
        (["United States", "Canada", "Australia"].includes(address.country) &&
          !address.state)
      )
        return setError("Address information required");
      setError(null);
      setShippingMethods(
        address.country === "United States"
          ? [
              {
                id: 1,
                name: "Untracked Shipping",
                rate: "5.00",
                color: "#307aff",
                info: "USPS First Class Mail",
              },
              {
                id: 2,
                name: "Tracked Shipping",
                rate: "8.00",
                color: "#ff5398",
                info: "USPS Ground Advantage with full tracking",
              },
            ]
          : [
              {
                id: 3,
                name: "International Shipping",
                rate: "16.00",
                color: "#307aff",
              },
            ]
      );
      setPage(3);
      setWorking(true);
    }
    if (page === 3) {
      setPage(4);
      setWorking(true);
    }
    if (page === 4) {
      if (window.isCheckingOut) {
        return;
      }
      window.isCheckingOut = true;
      const { token, error } = await props.stripe.createToken({ name });
      if (error) {
        window.isCheckingOut = false;
        return setError(error.message);
      }
      setError(null);
      setWorking(true);
      // Submit order
      let total = getTotal(
        props.option === "p",
        includeVol1,
        donations,
        shippingMethods.filter((i) => i.id === shippingMethod)[0]
      );
      const data = await (
        await fetch(apiRoot + "/order", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            address: address &&
              address.street1 && {
                ...address,
                country: countries.filter((i) => i.name === address.country)[0]
                  .code,
                state:
                  ["United States", "Canada", "Australia"].includes(
                    address.country
                  ) && address.state
                    ? countries
                        .filter((i) => i.name === address.country)[0]
                        .states.filter((i) => i.name === address.state)[0].code
                    : null,
              },
            trackedShipping: shippingMethod !== 1,
            name,
            email,
            totalAmountCents: total * 100,
            isPhysical: props.option === "p",
            includeVol1: includeVol1,
            donation_charity: donations.pcrf
              ? donations.pcrf.replace(".", "") * 1
              : 0,
            donation_form: donations.form
              ? donations.form.replace(".", "") * 1
              : 0,
            payment: {
              method: "stripe",
              token: token.id,
            },
          }),
        })
      ).json();
      setWorking(false);
      window.isCheckingOut = false;
      if (!data.success)
        return setError(
          data.message || "There was an error placing your order."
        );
      setPage(5);
    }
    if (page === 5) {
      props.close();
    }
  }

  async function handlePaypal(name, email, orderID) {
    setError(null);
    setWorking(true);
    // Submit order
    let total = getTotal(
      props.option === "p",
      includeVol1,
      donations,
      shippingMethods.filter((i) => i.id === shippingMethod)[0]
    );
    const data = await (
      await fetch(apiRoot + "/order", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          address: address.street1
            ? {
                ...address,
                country: countries.filter((i) => i.name === address.country)[0]
                  .code,
                state:
                  ["United States", "Canada", "Australia"].includes(
                    address.country
                  ) && address.state
                    ? countries
                        .filter((i) => i.name === address.country)[0]
                        .states.filter((i) => i.name === address.state)[0].code
                    : null,
              }
            : {},
          trackedShipping: shippingMethod !== 1,
          name,
          email,
          totalAmountCents: total * 100,
          isPhysical: props.option === "p",
          includeVol1: includeVol1,
          donation_charity: donations.pcrf
            ? donations.pcrf.replace(".", "") * 1
            : 0,
          donation_form: donations.form
            ? donations.form.replace(".", "") * 1
            : 0,
          payment: {
            method: "paypal",
            token: orderID,
          },
        }),
      })
    ).json();
    setWorking(false);
    if (!data.success)
      return setError(data.message || "There was an error placing your order.");
    setPage(5);
  }

  return (
    <Container>
      <CurrentCart>
        <CurrentCartTitle>Your Cart</CurrentCartTitle>
        <CartItem>
          <ItemName>
            FORMZINE Vol. 2 {props.option === "p" ? "Physical" : "Digital"}
          </ItemName>
          <ItemPrice>{props.option === "p" ? "$10.00" : "$5.00"}</ItemPrice>
        </CartItem>
        {includeVol1 && (
          <CartItem>
            <ItemName>FORMZINE Vol. 1 Digital</ItemName>
            <ItemPrice>$5.00</ItemPrice>
          </CartItem>
        )}
        {donations.pcrf > 0 && (
          <CartItem>
            <ItemName>PCRF Donation</ItemName>
            <ItemPrice>${Number(donations.pcrf).toFixed(2)}</ItemPrice>
          </CartItem>
        )}
        {donations.form > 0 && (
          <CartItem>
            <ItemName>FORM Donation</ItemName>
            <ItemPrice>${Number(donations.form).toFixed(2)}</ItemPrice>
          </CartItem>
        )}
        {shippingMethod && (
          <CartItem>
            <ItemName>
              {shippingMethods.filter((i) => i.id === shippingMethod)[0].name}
            </ItemName>
            <ItemPrice>
              ${shippingMethods.filter((i) => i.id === shippingMethod)[0].rate}
            </ItemPrice>
          </CartItem>
        )}
        <CartItem>
          <ItemName>
            <strong>Total</strong>
          </ItemName>
          <ItemPrice>
            $
            {getTotal(
              props.option === "p",
              includeVol1,
              donations,
              shippingMethods.filter((i) => i.id === shippingMethod)[0]
            ).toFixed(2)}
          </ItemPrice>
        </CartItem>
      </CurrentCart>
      <MainBox>
        <Header>
          <Title>{pageToTitle(page)}</Title>
          <Close onClick={props.close}>
            <svg height="36" width="36" viewBox="0 0 100 100">
              <g>
                <g fill="#000000">
                  <polygon points="50 47 71 26 74 29 53 50 74 71 71 74 50 53 29 74 26 71 47 50 26 29 29 26"></polygon>
                </g>
                <g fill="#000000">
                  <polygon points="50 47 71 26 74 29 53 50 74 71 71 74 50 53 29 74 26 71 47 50 26 29 29 26"></polygon>
                </g>
              </g>
            </svg>
          </Close>
        </Header>
        <Page visible={page === 1}>
          {page === 0 && (
            <AddFormzine1
              color={props.option === "p" ? "#307aff" : "#ff5398"}
              includeVol1={includeVol1}
              setIncludeVol1={setIncludeVol1}
            />
          )}
          {page === 1 && (
            <AddDonations
              donations={donations}
              setDonations={setDonations}
              physical={props.option === "p"}
            />
          )}
          {page === 2 && (
            <ShippingAddress address={address} setAddress={setAddress} />
          )}
          {page === 3 && (
            <ShippingMethod
              color={props.option === "p" ? "#307aff" : "#ff5398"}
              shippingMethods={shippingMethods}
              shippingMethod={shippingMethod}
              setShippingMethod={(x) => {
                setShippingMethod(x);
                setWorking(false);
              }}
            />
          )}
          {page === 4 && (
            <Checkout
              selectStripe={() => setWorking(false)}
              email={email}
              setEmail={setEmail}
              name={name}
              setName={setName}
              total={getTotal(
                props.option === "p",
                includeVol1,
                donations,
                shippingMethods.filter((i) => i.id === shippingMethod)[0]
              )}
              handlePaypal={handlePaypal}
              address={address}
              countries={countries}
            />
          )}
          {page === 5 && <Complete />}
        </Page>
        <Footer>
          {page !== 5 ? (
            <LightButton
              onClick={() => {
                page === 0
                  ? props.close()
                  : setPage(page === 4 && props.option === "d" ? 1 : page - 1);
                setWorking(false);
                setError(null);
              }}
            >
              {page === 0 ? "Close" : "Back"}
            </LightButton>
          ) : (
            <div />
          )}
          {error && <ErrorMessage>{error}</ErrorMessage>}
          <Button
            noflex
            color={props.option === "p" ? "#307aff" : "#ff5398"}
            onClick={nextStep}
            disabled={working}
          >
            {pageToButton(page)}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="24"
              height="24"
            >
              <path
                fill={working ? "#333" : "white"}
                d="M18.59 13H3a1 1 0 0 1 0-2h15.59l-5.3-5.3a1 1 0 1 1 1.42-1.4l7 7a1 1 0 0 1 0 1.4l-7 7a1 1 0 0 1-1.42-1.4l5.3-5.3z"
              />
            </svg>
          </Button>
        </Footer>
      </MainBox>
    </Container>
  );
}

const clickOutsideConfig = {
  handleClickOutside: () => Cart.handleClickOutside,
};

const ClickOutsideCart = onClickOutside(injectStripe(Cart), clickOutsideConfig);

function Overlay(props) {
  return (
    <Background>
      <ClickOutsideCart close={props.close} option={props.option} />
    </Background>
  );
}

export default Overlay;
